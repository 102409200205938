import { IoWarning } from 'react-icons/io5'
import { Box, Heading, HStack, Text } from '@chakra-ui/react'
import { toast } from 'react-toastify';

// Toast erreur générique
const Container = (props) => <div>{props.children}</div>;

const Error = (title, message) => { 
    const toastContent = (
        <Container>
            <Box>
                <HStack>
                    <IoWarning size="1.4em" />
                    <Text pt="2px" fontWeight="bold">{title}</Text>
                </HStack>
                <Text fontSize="0.9em" mt={1}>{message}</Text>
            </Box>
        </Container>
    )

    return toast.error(toastContent)
}

export default Error;
