export const TRANSLATIONS = {
  en_US: {
    languageChoiceTitle:  "Choose a language",
  },

  fr_BE: {
    languageChoiceTitle: "Choisir une langue",
    registerInThreeSteps: "Inscrivez-vous en seulement trois étapes",
    mySociety: "Ma société",
    siretLabel: "numéro de siret",
    societyInfos: "Société :",
    nextStepBtn: "Étape suivante",
    previousStepBtn: "Étape précédente",
    youAre: "Vous êtes ...",
    confirmBtn: "Je confirme",
  },

  pt_BR: {
    languageChoiceTitle: "Escolher uma língua",
  },

  de_DE: {
    languageChoiceTitle:  "Sprache auswählen",
  },
    
    
};
  
export const LANG_NAMES = [
  { locale: 'en_US', name: 'EN' },
  { locale: 'fr_BE', name: 'FR' },
  { locale: 'pt_BR', name: 'PT' },
  { locale: 'de_DE', name: 'DE' },
]