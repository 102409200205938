import React, { Component } from 'react';
import './Register.css';
import {Formik, Form, Field} from 'formik';
import * as yup from 'yup';
import { Box, Text, Heading, FormControl, Input, Button, Center, FormErrorMessage, HStack, FormLabel, Grid, GridItem, Image, Flex, Divider, SimpleGrid, RadioGroup, Stack, Radio } from "@chakra-ui/react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {FaArrowRight, FaCheck} from 'react-icons/fa';
import {ImArrowDown, ImArrowRight2, ImArrowLeft2} from 'react-icons/im';
import {IoMdArrowRoundDown, IoMdSettings} from 'react-icons/io';
import {IoHomeSharp} from 'react-icons/io5';
import Logo from '../../images/SOFITEX_ONLINE_LOGO_BLANC.png';
import AxiosCenter from '../../axios/AxiosCenter';
import Error from '../../axios/toasts/Error';

import TranslationContainer from '../../languages/Translation/TranslationContainer';
import LangSwitchContainer from '../../languages/LangSwitch/LangSwitchContainer';
import { Link, Redirect } from 'react-router-dom';


export default class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
        modalSocieteIsOpen: false,
        nomSociete: '',
        rueSociete: '',
        cpSociete: '',
        villeSociete: '',
        societe: '',
        siret: '',
        confirmedSociete: false,
        step: 1,
        civilite: 'Madame',
        prenom: '',
        nom: '',
        email: '',
        confirmationemail: '',
        telephone: '',
        validateLoading: false,
        modalLanguageIsOpen: false,
    };
    this.openModalSociete = this.openModalSociete.bind(this);
    this.closeModalSociete = this.closeModalSociete.bind(this);
    this.toggleModalLanguage = this.toggleModalLanguage.bind(this);
    this.closeModalLanguage = this.closeModalLanguage.bind(this);
    this.getEntreprise = this.getEntreprise.bind(this);
    this.gotoStepTwo = this.gotoStepTwo.bind(this);
    this.backToStepOne = this.backToStepOne.bind(this);
    this.goToStepThree = this.goToStepThree.bind(this);
    this.backToStepTwo = this.backToStepTwo.bind(this);
    this.validateInscription = this.validateInscription.bind(this);
}

  // Ouverture du modal Societe
  openModalSociete() {
      this.setState({
          modalSocieteIsOpen: true,
      })
  }

  // Ouverture du modal Societe
  closeModalSociete() {
      this.setState({
        modalSocieteIsOpen: false,
        nomSociete: '',
        rueSociete: '',
        cpSociete: '',
        villeSociete: '',
        societe: '',
        siret: '',
      })
  }

  toggleModalLanguage() {
    this.setState({
      modalLanguageIsOpen: true
    })
  }

  closeModalLanguage() {
    this.setState({
      modalLanguageIsOpen: false
    })
  }

  getEntreprise(values) {
    this.setState({
      confirmedSociete: false
    })
    fetch('https://entreprise.data.gouv.fr/api/sirene/v3/etablissements/' + values.siret)
    .then(response => response.json())
    .then(data => {
      this.setState({
        nomSociete: data.etablissement.unite_legale.denomination,
        rueSociete: data.etablissement.geo_l4,
        cpSociete: data.etablissement.code_postal,
        villeSociete: data.etablissement.libelle_commune,
        societe: data.etablissement,
        siret: data.etablissement.siret,
      }, () => this.openModalSociete())
      console.log(data)
    })
    .catch((error) => {
      Error("Erreur SIRET", "le numéro de siret est inconnu ou la société n'est plus active.");
    })
  }

  confirmSociete() {
    this.setState({
      confirmedSociete: true,
      modalSocieteIsOpen: false,
    });
  }

  gotoStepTwo() {
    this.setState({
      step: 2,
    })
  }

  backToStepOne() {
    this.setState({
      step: 1,
    })
  }

  goToStepThree(values) {
    this.setState({
      civilite: values.civilite,
      prenom: values.prenom,
      nom: values.nom,
      email: values.email,
      confirmationemail: values.confirmationemail,
      telephone: values.telephone,
      step: 3,
    })
    console.log(values)
  }

  backToStepTwo() {
    this.setState({
      step: 2,
    })
  }

  validateInscription() {
    this.setState({
      validateLoading: true
    })

    let data = {
      siret: this.state.siret,
      nomSociete: this.state.nomSociete,
      rueSociete: this.state.rueSociete,
      cpSociete: this.state.cpSociete,
      villeSociete: this.state.villeSociete,
      civilite: this.state.civilite,
      prenom: this.state.prenom,
      nom: this.state.nom,
      email: this.state.email,
      telephone: this.state.telephone,
    }

    AxiosCenter.inscriptionProspect(data)
    .then(response => {
      alert(JSON.stringify(data))
      this.setState({
        validateLoading: false,
      })
    })
    .catch(error => {
      console.log(error)
      this.setState({
        validateLoading: false,
      })
    })
  }

  render() {

    const { modalSocieteIsOpen, confirmedSociete, societe, nomSociete, rueSociete, cpSociete, villeSociete, siret, step, civilite, prenom, nom, email, confirmationemail, telephone, validateLoading, modalLanguageIsOpen } = this.state;

    const initialStepOneValues = {
      siret: siret,
    }

    const initialStepTwoValues = {
      civilite: civilite,
      nom: nom,
      prenom: prenom,
      email: email,
      confirmationemail: confirmationemail,
      telephone: telephone,
    }

    // Gestion des erreurs du formulaire code avec yup
    const siretSchema = yup.object().shape({
      siret: yup
      .string()
      .label('code')
      .required("le numéro de siret est obligatoire")
      .min(14, "le numéro de siret doit comporter 14 chiffres")
      .max(14, "le numéro de siret doit comporter 14 chiffres")
      .matches(/^[0-9]*$/, "le numéro de siret doit être numérique")
    });

    // Gestion des erreurs du formulaire code avec yup
    const personalInfosSchema = yup.object().shape({
      nom: yup
      .string()
      .label('nom')
      .required("le nom est obligatoire"),
      prenom: yup
      .string()
      .label('prenom')
      .required("le prenom est obligatoire"),
      email: yup
      .string()
      .label('email')
      .required("l'email est obligatoire")
      .matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)/ , "email non valide"),
      confirmationemail: yup
      .string()
      .label('confirmationemail')
      .required("veuillez confirmer votre adresse email")
      .test('match', 
        "la confirmation ne correspond pas à l'email que vous avez saisi", 
        function(confirmEmail) { 
          return confirmEmail === this.parent.email; 
        }),
      telephone: yup
      .string()
      .label('code')
      .required("le numéro de téléphone est obligatoire"),
    });

    switch (step) {
      case 1:
        return (
          <Box minHeight="100vh">
            <Box p={2} backgroundColor="#2f2f2f">
              <HStack>
                <Image mt={1} mr="auto" src={Logo} alt="logo sofitex-online" width={{base: "170px", md: "200px", lg: "300px"}} />
                <Link to="/">
                  <Box pr={3}>
                    <IoHomeSharp size="1.3em" color="#f3f5f3"/>
                  </Box>
                </Link>
                <Box as="button" onClick={this.toggleModalLanguage} pr={1}>
                  <IoMdSettings size="1.3em" color="#f3f5f3"/>
                </Box>
              </HStack>
              <Divider mt={3} colorScheme="gray" />
              <Heading mt={5} mb={10} textAlign="center" color="#f3f5f3"><TranslationContainer translationKey="registerInThreeSteps"/></Heading>
            </Box>
            <Center height={0} mb={9}>
              <Box position="relative" zIndex={2} bottom={"0px"}>
                <IoMdArrowRoundDown size="6em" color="#00de00" />
              </Box>
            </Center>
            <Grid p={1} templateColumns="repeat(12, 1fr)">
              <GridItem colSpan={{base: 12, md: 10, lg: 6}} colStart={{base: 1, md: 2, lg: 4}}>
                <Flex mx="auto" mt={5} alignItems="center" justifyContent="center" boxSize="90px" rounded="full" border="1px solid #00de00">
                  <Text fontSize="3xl" fontWeight="bold">01</Text>
                </Flex>
                <Text textAlign="center" fontWeight="bold" fontSize="2xl"><TranslationContainer translationKey="mySociety"/></Text>
                <Center border="1px solid lightgray" p={2} my={5} rounded="lg" backgroundColor="white">
                  <Box width="100%">
                  <Formik
                    initialValues = {initialStepOneValues}
                    validationSchema={siretSchema}
                    onSubmit = { this.getEntreprise }
                  >
                    {formikProps => (
                      <Form >
                        <FormLabel mb={0}><TranslationContainer translationKey="siretLabel"/></FormLabel>
                        <HStack alignItems="flex-start">
                          <FormControl isInvalid={formikProps.errors.siret && formikProps.touched.siret}>
                            <Field
                              id='siret'
                              name="siret"
                            >
                              {({field, form, meta}) => 
                                <Input 
                                  {...field} 
                                  type="text"
                                  focusBorderColor="#00ed00"
                                  rounded="md"
                                  maxLength="14"
                                  size="md"
                                  value={formikProps.values.siret}
                                  onChange={formikProps.handleChange("siret")}
                                />
                              }
                            </Field>
                            <FormErrorMessage className="font-style ml-1">{formikProps.errors.siret}</FormErrorMessage>
                          </FormControl>
                          <Button mt={3} minHeight="40px" type="submit"><FaArrowRight /></Button>                        
                        </HStack>
                      </Form>
                    )}
                  </Formik>
                  {confirmedSociete && societe !== '' &&
                  <>
                  <Box p={3} mt={3} border="1px solid lightgray" rounded="md" backgroundColor="#F9FBFF">
                    <Text><TranslationContainer translationKey="societyInfos"/></Text>
                    <Text fontWeight="bold" fontSize="lg">{societe.unite_legale.denomination}</Text>
                    <Text>{societe.geo_l4}</Text>
                    <Text>{societe.code_postal} {societe.libelle_commune}</Text>
                  </Box>
                  <HStack justifyContent={{sm: 'flex-end'}}>
                    <Button className="button" width={{base: "100%", sm: "auto"}} alignSelf='flex-end' mt={5} variant="outline" borderColor="#00de00" onClick={this.gotoStepTwo}><TranslationContainer translationKey="nextStepBtn"/> <Box ml={2}><ImArrowRight2 /></Box></Button>
                  </HStack>
                  </>
                  }
                  </Box>
                </Center>
              </GridItem>
              {/* Modal Choix de la langue */}
              <Modal isOpen={modalLanguageIsOpen} size="sm" centered={true} toggle={this.closeModalLanguage}>
                <ModalHeader toggle={this.closeModalLanguage}>
                  <Text className="font-style" fontSize="lg" fontWeight="bold"><TranslationContainer translationKey="languageChoiceTitle"/></Text>
                </ModalHeader>
                <ModalBody>
                  <LangSwitchContainer />
                </ModalBody>
              </Modal>
            </Grid>

            {/* Modal Confirmer Société */}
            <Modal isOpen={modalSocieteIsOpen} size="sm" centered={true} toggle={this.closeModalSociete}>
              <ModalHeader toggle={this.closeModalSociete}>
                <Text fontSize="xl" fontWeight="bold"><TranslationContainer translationKey="youAre"/></Text>
              </ModalHeader>
              {societe !== '' &&            
              <ModalBody>
                <Box width="100%" textAlign="center">
                  <Text fontWeight="bold" fontSize="lg">{societe.unite_legale.denomination}</Text>
                  <Text>{societe.geo_l4}</Text>
                  <Text>{societe.code_postal} {societe.libelle_commune}</Text>
                </Box>
              </ModalBody>
              }
              <ModalFooter>
                <Button className="button2" width="100%" borderColor="#00de00" onClick={() => this.confirmSociete()}><TranslationContainer translationKey="confirmBtn"/></Button>
              </ModalFooter>                 
            </Modal>
          </Box>
      );
      case 2:
        return (
          <Box minHeight="100vh">
            <Box p={2} backgroundColor="#2f2f2f">
              <HStack>
                <Image mt={1} mr="auto" src={Logo} alt="logo sofitex-online" width={{base: "170px", md: "200px", lg: "300px"}} />
                <Box as="button" onClick={this.toggleModalLanguage} pr={1}>
                  <IoMdSettings size="1.3em" color="#f3f5f3"/>
                </Box>
              </HStack>
              <Divider mt={3} colorScheme="gray" />
              <Heading mt={5} mb={10} textAlign="center" color="#f3f5f3"><TranslationContainer translationKey="registerInThreeSteps"/></Heading>
            </Box>
            <Center height={0} mb={9}>
              <Box position="relative" zIndex={2} bottom={"0px"}>
                <IoMdArrowRoundDown size="6em" color="#00de00" />
              </Box>
            </Center>
            <Grid p={1} templateColumns="repeat(12, 1fr)">
              <GridItem colSpan={{base: 12, md: 10, lg: 6}} colStart={{base: 1, md: 2, lg: 4}}>
                <Flex mx="auto" mt={5} alignItems="center" justifyContent="center" boxSize="90px" rounded="full" border="1px solid #00de00">
                  <Text fontSize="3xl" fontWeight="bold">02</Text>
                </Flex>
                <Text textAlign="center" fontWeight="bold" fontSize="2xl">Mes informations personnelles</Text>
                <Center border="1px solid lightgray" py={3} px={3} my={5} rounded="lg" backgroundColor="white">
                  <Formik
                    initialValues = {initialStepTwoValues}
                    validationSchema={personalInfosSchema}
                    onSubmit = { this.goToStepThree }
                  >
                    {formikProps => (
                      <Box width="100%">
                        <Form noValidate>
                          <Grid templateColumns="repeat(2, 1fr)" rowGap={3} columnGap={3}>
                            <GridItem colSpan={2}>
                              <FormLabel mb={0}>civilité</FormLabel>
                              <RadioGroup 
                                name="civilite"
                                defaultValue={civilite} 
                                onChange={formikProps.handleChange("civilite")}
                                mb={3}
                              >
                                <Stack spacing={5} direction="row">
                                    <Radio colorScheme="green" value="Madame">
                                      Madame
                                    </Radio>
                                    <Radio colorScheme="green" value="Monsieur">
                                      Monsieur
                                    </Radio>
                                </Stack>
                              </RadioGroup>
                              <FormErrorMessage className="font-style ml-1">{formikProps.errors.civilite}</FormErrorMessage>
                            </GridItem>
                            <GridItem colSpan={{base: 2, md: 1}}>
                              <FormLabel mb={0}>prénom</FormLabel>
                              <FormControl isInvalid={formikProps.errors.prenom && formikProps.touched.prenom}>
                                <Field
                                  id='prenom'
                                  name="prenom"
                                >
                                  {({field, form, meta}) => 
                                    <Input 
                                      {...field} 
                                      type="text"
                                      focusBorderColor="#00ed00"
                                      rounded="md"
                                      size="md"
                                      onChange={formikProps.handleChange("prenom")}
                                    />
                                  }
                                </Field>
                                <FormErrorMessage className="font-style ml-1">{formikProps.errors.prenom}</FormErrorMessage>
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{base: 2, md: 1}}>
                              <FormLabel mb={0}>nom</FormLabel>
                              <FormControl isInvalid={formikProps.errors.nom && formikProps.touched.nom}>
                                <Field
                                  id='nom'
                                  name="nom"
                                >
                                  {({field, form, meta}) => 
                                    <Input 
                                      {...field} 
                                      type="text"
                                      focusBorderColor="#00ed00"
                                      rounded="md"
                                      size="md"
                                      value={formikProps.values.nom}
                                      onChange={formikProps.handleChange("nom")}
                                    />
                                  }
                                </Field>
                                <FormErrorMessage className="font-style ml-1">{formikProps.errors.nom}</FormErrorMessage>
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{base: 2, md: 1}}>
                              <FormLabel mb={0}>email</FormLabel>
                              <FormControl isInvalid={formikProps.errors.email && formikProps.touched.email}>
                                <Field
                                  id='email'
                                  name="email"
                                >
                                  {({field, form, meta}) => 
                                    <Input 
                                      {...field} 
                                      type="email"
                                      focusBorderColor="#00ed00"
                                      rounded="md"
                                      size="md"
                                      value={formikProps.values.email}
                                      onChange={formikProps.handleChange("email")}
                                    />
                                  }
                                </Field>
                                <FormErrorMessage className="font-style ml-1">{formikProps.errors.email}</FormErrorMessage>
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{base: 2, md: 1}}>
                              <FormLabel mb={0}>confirmation email</FormLabel>
                              <FormControl isInvalid={formikProps.errors.confirmationemail && formikProps.touched.confirmationemail}>
                                <Field
                                  id='confirmationemail'
                                  name="confirmationemail"
                                >
                                  {({field, form, meta}) => 
                                    <Input 
                                      {...field} 
                                      type="email"
                                      focusBorderColor="#00ed00"
                                      rounded="md"
                                      size="md"
                                      value={formikProps.values.confirmationemail}
                                      onChange={formikProps.handleChange("confirmationemail")}
                                    />
                                  }
                                </Field>
                                <FormErrorMessage className="font-style ml-1">{formikProps.errors.confirmationemail}</FormErrorMessage>
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={2}>
                              <FormLabel mb={0}>téléphone</FormLabel>
                              <FormControl isInvalid={formikProps.errors.telephone && formikProps.touched.telephone}>
                                <Field
                                  id='telephone'
                                  name="telephone"
                                >
                                  {({field, form, meta}) => 
                                    <Input 
                                      {...field} 
                                      type="text"
                                      focusBorderColor="#00ed00"
                                      rounded="md"
                                      size="md"
                                      value={formikProps.values.telephone}
                                      onChange={formikProps.handleChange("telephone")}
                                    />
                                  }
                                </Field>
                                <FormErrorMessage className="font-style ml-1">{formikProps.errors.telephone}</FormErrorMessage>
                              </FormControl>
                            </GridItem>
                          </Grid>
                          <Flex justifyContent="space-evenly">
                            <Button className="cancel-button" width={{base: "100%", sm: "auto"}} mt={5} mr="auto" type="button" onClick={this.backToStepOne}><Box mr={2}><ImArrowLeft2 /></Box>Étape précédente</Button>
                            <Button className="button" width={{base: "100%", sm: "auto"}} variant="outline" borderColor="#00de00" mt={5} type="submit">Étape suivante <Box ml={2}><ImArrowRight2 /></Box></Button>
                          </Flex>                       
                        </Form>
                      </Box>
                    )}
                  </Formik>
                </Center>
              </GridItem>
            </Grid>
            {/* Modal Choix de la langue */}
            <Modal isOpen={modalLanguageIsOpen} size="sm" centered={true} toggle={this.closeModalLanguage}>
              <ModalHeader toggle={this.closeModalLanguage}>
                <HStack>
                    <IoMdSettings size="1.2em" color="#00de00"/>
                    <Text className="font-style" fontSize="lg" fontWeight="bold"><TranslationContainer translationKey="languageChoiceTitle"/></Text>
                </HStack>
              </ModalHeader>
              <ModalBody>
                <LangSwitchContainer />
              </ModalBody>
            </Modal>
          </Box>
      );
      case 3:
        return (
          <Box minHeight="100vh">
            <Box p={2} backgroundColor="#2f2f2f">
              <HStack>
                <Image mt={1} mr="auto" src={Logo} alt="logo sofitex-online" width={{base: "170px", md: "200px", lg: "300px"}} />
                <Box as="button" onClick={this.toggleModalLanguage} pr={1}>
                  <IoMdSettings size="1.3em" color="#f3f5f3"/>
                </Box>
              </HStack>
              <Divider mt={3} colorScheme="gray" />
              <Heading mt={5} mb={10} textAlign="center" color="#f3f5f3"><TranslationContainer translationKey="registerInThreeSteps"/></Heading>
            </Box>
            <Center height={0} mb={9}>
              <Box position="relative" zIndex={2} bottom={"0px"}>
                <IoMdArrowRoundDown size="6em" color="#00de00" />
              </Box>
            </Center>
            <Grid p={1} templateColumns="repeat(12, 1fr)">
              <GridItem colSpan={{base: 12, md: 10, lg: 6}} colStart={{base: 1, md: 2, lg: 4}}>
                <Flex mx="auto" mt={5} alignItems="center" justifyContent="center" boxSize="90px" rounded="full" border="1px solid #00de00">
                  <Text fontSize="3xl" fontWeight="bold">03</Text>
                </Flex>
                <Text textAlign="center" fontWeight="bold" fontSize="2xl">Validation</Text>
                <Center border="1px solid lightgray" py={3} px={3} my={5} rounded="lg" backgroundColor="white">
                  <SimpleGrid width="100%">
                    <Text textAlign="center" fontSize="xl">Validez votre inscription et découvrez nos intérimaires qualifiés, à deux pas de chez vous et correspondant à vos besoins.</Text>
                    <Button className="button" mt={5} type="button" variant="outline" borderColor="#00de00" color="#00de00" fontSize="xl" size="lg" isLoading={validateLoading} onClick={this.validateInscription}><Box mr={2}><FaCheck /></Box>Je valide mon inscription</Button>
                    <Button className="cancel-button" mt={5} type="button" size="lg" onClick={this.backToStepTwo}><Box mr={2}><ImArrowLeft2 /></Box>Retour à l'étape précédente</Button>
                  </SimpleGrid>
                </Center>
              </GridItem>
            </Grid>
            {/* Modal Choix de la langue */}
            <Modal isOpen={modalLanguageIsOpen} size="sm" centered={true} toggle={this.closeModalLanguage}>
              <ModalHeader toggle={this.closeModalLanguage}>
                <HStack>
                    <IoMdSettings size="1.2em" color="#00de00"/>
                    <Text className="font-style" fontSize="lg" fontWeight="bold"><TranslationContainer translationKey="languageChoiceTitle"/></Text>
                </HStack>
              </ModalHeader>
              <ModalBody>
                <LangSwitchContainer />
              </ModalBody>
            </Modal>
          </Box>
      );
    }
  }
}

