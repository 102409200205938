import React, { Component } from 'react';
import { Text } from "@chakra-ui/react";

export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
        modalLanguageIsOpen: false,
    };
    this.toggleModalLanguage = this.toggleModalLanguage.bind(this);
    this.closeModalLanguage = this.closeModalLanguage.bind(this);
}

  toggleModalLanguage() {
    this.setState({
      modalLanguageIsOpen: true
    })
  }

  closeModalLanguage() {
    this.setState({
      modalLanguageIsOpen: false
    })
  }

  render() {
    return(
        <Text>HOME</Text>
    )
  }
}