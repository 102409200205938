import Cookies from 'universal-cookie'
import jwtDecode from 'jwt-decode';

const cookie = new Cookies();

const TokenService = {

    // Création du cookie contenant le jwt
    setToken(token){
        cookie.set('token', token, { maxAge: 31536000, sameSite: "strict" });
    },

    // Récupération du JWT via le cookie
    getToken(){
        return cookie.get('token');
    },

    // Décodage du jwt
    getDecodedToken() {
        try {
            return jwtDecode(this.getToken());
        } catch (error) {
            return null
        }
    },

    // Suppression du cookie contenant le jwt
    removeToken(){
        return cookie.remove('token');
    },

    // Création du cookie contenant la langue souhaitée
    setLanguageToken(language){
        cookie.set('lang', language);
    },

    // Récupération de la langue souhaitée via le cookie
    getLanguageToken(){
        return cookie.get('lang');
    },
    

}

export default TokenService;