import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import TokenService from '../../axios/TokenService';
import FlagEN from '../../images/en-flag.png'
import FlagFR from '../../images/fr-flag.png'
import FlagPO from '../../images/por-flag.png'
// import FlagLU from '../../images/lux-flag.png'
// import FlagIT from '../../images/ita-flag.png'
import FlagAL from '../../images/all-flag.png'
// import FlagES from '../../images/esp-flag.png'
import { Image } from '@chakra-ui/image';
import { HStack, Box } from '@chakra-ui/layout';

export default class LangSwitch extends Component {

  render() {

    const flagEng = <Image width="60px" src={FlagEN} />
    const flagFra = <Image width="60px" src={FlagFR} />
    const flagPor = <Image width="60px" src={FlagPO} />
    // const flagLux = <Image width="40px" src={FlagLU} />
    // const flagIta = <Image width="40px" src={FlagIT} />
    const flagAll = <Image width="60px" src={FlagAL} />
    // const flagEsp = <Image width="40px" src={FlagES} />

    return (
      <HStack justifyContent="center">
        <Box as="button" onClick={() => {this.props.setLanguage("fr_BE");TokenService.setLanguageToken("fr_BE");}}>{flagFra}</Box>
        <Box as="button" onClick={() => {this.props.setLanguage("en_US");TokenService.setLanguageToken("en_US");}}>{flagEng}</Box>
        {/* <Box as="button" onClick={() => {this.props.setLanguage("esp");TokenService.setLanguageToken("esp");}}>{flagEsp}</Box> */}
        <Box as="button" onClick={() => {this.props.setLanguage("pt_BR");TokenService.setLanguageToken("pt_BR");}}>{flagPor}</Box>
        {/* <Box as="button" onClick={() => {this.props.setLanguage("ita");TokenService.setLanguageToken("ita");}}>{flagIta}</Box> */}
        <Box as="button" onClick={() => {this.props.setLanguage("de_DE");TokenService.setLanguageToken("de_DE");}}>{flagAll}</Box>
        {/* <Box as="button" onClick={() => {this.props.setLanguage("lux");TokenService.setLanguageToken("lux");}}>{flagLux}</Box> */}
      </HStack>
    );
  }
}

LangSwitch.propTypes = {
  locale: PropTypes.string.isRequired,
  setLanguage: PropTypes.func,
};