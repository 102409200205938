import apiSofitexOnlineBackEnd from './ApiSofitexOnlineBackEnd';

const AxiosCenter = {

    // Requête Get informations de l'utilisateur
    inscriptionProspect(data){
        return apiSofitexOnlineBackEnd({
            method: 'post',
            url: '/prospect',
            data: data
        })
    }

}

export default AxiosCenter;
