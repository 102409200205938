import { ChakraProvider } from '@chakra-ui/react';
import Routes from './routing/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TokenService from './axios/TokenService';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { rootReducer } from './rootReducer';

function App() {

    // Récupération du token décodé si présence du jwt dans les cookies
    const token = TokenService.getDecodedToken();
    if(token !== null) {
        if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage( JSON.stringify({type: "IDUser", data: JSON.parse(token.aud)}))
        }
    }

    const store = createStore(rootReducer);

    return (
        <div className="App">
            <Provider store={store}>      
                <ChakraProvider>
                    <Router>
                        <Routes token={token}/>
                    </Router>     
                    <ToastContainer 
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </ChakraProvider>
            </Provider>
        </div>
    );

}

export default App;