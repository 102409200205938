import TokenService from '../../axios/TokenService';
import { SET_LANGUAGE } from '../../constants/generalConstants';

// Initial state
export const initialState = {
  locale: TokenService.getLanguageToken() ? TokenService.getLanguageToken() : 'fr_BE', // default locale
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, locale: action.locale };
    default:
      return state;
  }
}