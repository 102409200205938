import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "../components/home/Home";
import Register from "../components/inscription/Register";
import RouteNotFound from "./RouteNotFound";

// Routes sécurisées qui nécessitent un token
const PrivateUserRoute = ({ component: Component, token, ...rest}) => (
    <Route
        {...rest}    
        render={props =>                    
            (token && token !== "") 
                ? (
                    <Component {...props} token={token}/> 
                   )
                : 
                (!token && (<Redirect to={{ pathname :"/login" }} />))

        }
    />
)

const PublicRoute = ({ component: Component, token, ...rest}) => (
    <Route
        {...rest}    
        render={props =>                    
            (token && token !== "")                    
                ? (<Redirect to={{ pathname :"/dashboard" }} />)
                : (<Component {...props} token={token} />) 
        }
    />
)

class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // token: this.props.token,
        };
    }

    render() {

        const {token} = this.state;

        return (
            <Switch>
                {/*Public Routes */}
                <PublicRoute token={token} exact path="/home" component={Home} />         
                <PublicRoute token={token} exact path="/" component={Home} />
                <PublicRoute token={token} exact path="/register" component={Register} />
                {/* Private Routes */}

                {/* Route not found */}
                <Route component={RouteNotFound} />
            </Switch>
        )
    } 
}
export default Routes;