import Axios from 'axios';
import ApiConfigUrl from './ApiConfigUrl';
// import TokenService from './TokenService';

// Création de l'instance d'Axios + configuration de la base URL des requêtes
const apiSofitexOnlineBackEnd = Axios.create({
    baseURL: ApiConfigUrl,
})

// Interception des requêtes avant envoi pour y insérer l'autorisation avec le jwt dans l'entête
// apiSofitexOnlineBackEnd.interceptors.request.use(req => {
//     TokenService.getToken() !== null ?
//     req.headers['Authorization'] = 'Bearer ' + TokenService.getToken() :
//     req.headers['Authorization'] = ''
//     return req
// })

export default apiSofitexOnlineBackEnd;